import { FeatureFlags, UserRoles } from '@chiroup/core';
import React from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { MeContext } from '../../../contexts/me.context';
import Tabs from '../../layout/Tabs';

const ClinicSettingsTabs: React.FC = () => {
  const { pathname } = useLocation();
  const { hasAccess, hasRole } = useContext(MeContext);

  const tabs = useMemo(() => {
    const theTabs = [];
    if (hasRole([UserRoles.Admin, UserRoles.ContentDatabaseEditor])) {
      theTabs.push(
        ...[
          {
            name: 'General',
            href: `/settings/clinic`,
            active: pathname === `/settings/clinic`,
          },
          {
            name: 'Locations',
            href: `/settings/clinic/locations`,
            active: pathname === `/settings/clinic/locations`,
          },
          {
            name: 'Users',
            href: `/settings/clinic/users`,
            active: pathname === `/settings/clinic/users`,
          },
          {
            name: 'Survey preferences',
            href: `/settings/clinic/patient-preferences`,
            active: pathname === `/settings/clinic/patient-preferences`,
          },
        ],
      );
    }

    const extraTabs = [];

    if (hasRole([UserRoles.Admin, UserRoles.Staff])) {
      extraTabs.push({
        name: 'Kiosks',
        href: `/settings/clinic/kiosks`,
        active: pathname === `/settings/clinic/kiosks`,
      });
    }

    if (hasRole([UserRoles.Admin, UserRoles.Staff])) {
      if (hasAccess(FeatureFlags.ehr)) {
        extraTabs.push({
          name: 'Credit Devices',
          href: `/settings/clinic/cc-devices`,
          active: pathname === `/settings/clinic/cc-devices`,
        });
      }
    }

    if (hasAccess(FeatureFlags.scheduling)) {
      if (hasRole([UserRoles.Admin, UserRoles.ContentDatabaseEditor])) {
        extraTabs.push({
          name: 'Disciplines',
          href: `/settings/clinic/disciplines`,
          active: pathname === `/settings/clinic/disciplines`,
        });
      }
      if (hasRole([UserRoles.Admin])) {
        extraTabs.push({
          name: 'Scheduling',
          href: `/settings/clinic/scheduling`,
          active: pathname === `/settings/clinic/scheduling`,
        });
      }
      if (hasAccess(FeatureFlags.superAppointments)) {
        extraTabs.push({
          name: 'Dashboard',
          href: `/settings/clinic/dashboard/`,
          active: pathname.includes(`/settings/clinic/dashboard`),
        });
      }
      if (hasRole([UserRoles.Admin, UserRoles.Biller])) {
        extraTabs.push({
          name: 'Billing',
          href: `/settings/clinic/billing`,
          active: pathname === `/settings/clinic/billing`,
        });
      }
    }
    theTabs.push(...extraTabs);
    return theTabs;
  }, [pathname, hasAccess, hasRole]);

  return <Tabs tabs={tabs} />;
};

export default ClinicSettingsTabs;
