import { ClinicBillingSettings, Macro, WorkflowStep } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

const settingsService = () => {
  const saveMacros = async (
    added: Partial<Macro>[],
    deleted: Partial<Macro>[],
    edited: Partial<Macro>[],
  ): Promise<{ statusCode: number; userMacros: Macro[] }> => {
    const body = {
      added,
      deleted,
      edited,
    };
    return ChiroUpAPI.post('api', `/settings/user/macro`, { body });
  };

  const saveBillingSettings = async (
    claim: Partial<ClinicBillingSettings>,
    clinicId?: number,
  ) => {
    const body = {
      ...claim,
    };
    return ChiroUpAPI.post('api', `/settings/${clinicId}/billing/`, {
      body,
    });
  };

  const getBillingSettings = async (clinicId?: number) => {
    return ChiroUpAPI.get('api', `/settings/${clinicId}/billing/`, {});
  };
  const getDisciplineTreatment = async (clinicId?: number, id?: number) => {
    return ChiroUpAPI.get(
      'api',
      `/settings/${clinicId}/discipline/treatment/${id}`,
      {},
    );
  };
  const getCustomSteps = async (clinicId?: number) => {
    return ChiroUpAPI.get('api', `/settings/${clinicId}/custom-steps/`, {});
  };

  const createWorkflow = async ({
    clinicId,
    workflow,
    isDefault,
  }: {
    clinicId?: number;
    workflow: WorkflowStep[];
    isDefault?: boolean;
  }) => {
    return ChiroUpAPI.post('api', `/settings/${clinicId}/workflow/`, {
      body: { workflow, isDefault },
    });
  };

  const getWorkflow = async ({
    clinicId,
    id,
  }: {
    clinicId?: number;
    id: string | number;
  }) => {
    return ChiroUpAPI.get('api', `/settings/${clinicId}/workflow/${id}`, {});
  };

  const updateWorkflowSteps = async ({
    clinicId,
    workflowId,
    steps,
  }: {
    clinicId?: number;
    workflowId: number;
    steps: WorkflowStep[];
  }) => {
    return ChiroUpAPI.put(
      'api',
      `/settings/${clinicId}/workflow/steps/${workflowId}`,
      {
        body: steps,
      },
    );
  };

  return {
    saveMacros,
    saveBillingSettings,
    getBillingSettings,
    getDisciplineTreatment,
    getCustomSteps,
    createWorkflow,
    getWorkflow,
    updateWorkflowSteps,
  };
};

export default settingsService();
