import {
  STRING_NUMBER_HASH,
  calculateResponsibilities,
  TransactionItemSubtypeEnum,
  createDecimal,
  AppointmentInsuranceType,
  PatientTransaction,
} from '@chiroup/core';
import Decimal from 'decimal.js';
import { useMemo } from 'react';

type Props = {
  insurances: Partial<AppointmentInsuranceType>[];
  services: any;
  workingTransaction: PatientTransaction | null;
  courtesyBilling: boolean;
  superBill: boolean;
};

const useResponsibilities = ({
  insurances,
  services,
  workingTransaction,
  courtesyBilling,
  superBill,
}: Props) => {
  const insurancesToUse = useMemo(() => {
    return insurances
      .sort(
        (a, b) => (a.billingPriority as number) - (b.billingPriority as number),
      )
      .map((insurance) => {
        const {
          payorID,
          insuranceName,
          billingPriority,
          deductible,
          coPay,
          coInsurance,
          serviceAllowedAmounts,
        } = insurance;
        const trivialAllowedAmount = Object.keys(
          serviceAllowedAmounts || {},
        ).reduce((acc, cur) => {
          if (!serviceAllowedAmounts) return acc;
          return acc + Number(serviceAllowedAmounts[cur].allowedAmount);
        }, 0);
        const compactServiceAmounts = Object.keys(
          serviceAllowedAmounts ?? {},
        ).reduce((acc, cur) => {
          if (!serviceAllowedAmounts) return acc;
          acc[cur] = Number(serviceAllowedAmounts[cur].allowedAmount);
          return acc;
        }, {} as STRING_NUMBER_HASH);
        return {
          payorId: payorID as number,
          payorName: insuranceName as string,
          billingPriority: billingPriority as number,
          deductible: Number(deductible) || 0,
          coPay: Number(coPay) || 0,
          coInsurance: Number(coInsurance) || 0,
          allowedAmount: trivialAllowedAmount,
          serviceAllowedAmounts: compactServiceAmounts,
          maxPerVisit: Number(insurance.maxPerVisit),
        };
      });
  }, [insurances]);

  const responsibilities = useMemo(() => {
    if (!services?.services?.length) {
      return { payorResponsibilities: {}, finalPatientResponsibility: 0 };
    }
    return calculateResponsibilities({
      insurances: insurancesToUse as any,
      items: workingTransaction?.items || [],
      courtesyBilling,
      superBill,
      allocateToPatient: workingTransaction?.allocateToPatient,
      allocationAmount: workingTransaction?.allocatedFromClaimsAmount,
      nonBillable: insurances?.some((ins) => !ins.billable),
    });
  }, [
    services?.services?.length,
    insurancesToUse,
    workingTransaction?.items,
    courtesyBilling,
    superBill,
    workingTransaction?.allocateToPatient,
    workingTransaction?.allocatedFromClaimsAmount,
    insurances,
  ]);

  const payorResponsibilityTotal = Object.values(
    responsibilities.payorResponsibilities,
  )?.reduce((acc, cur) => acc + Number(cur.amount), 0);

  const balanceAllocatedToPatient = useMemo(() => {
    return (workingTransaction?.items ?? [])
      .filter(
        (i) =>
          i.subtype === TransactionItemSubtypeEnum.BalanceAllocatedToPatient,
      )
      .reduce((acc: Decimal, item) => {
        return acc.plus(Number(item.amount) || 0);
      }, createDecimal(0))
      .toDP(2);
  }, [workingTransaction?.items]);

  const patientResponsibility = useMemo(() => {
    // console.log({ responsibilities });
    return createDecimal(responsibilities.finalPatientResponsibility)
      .plus(balanceAllocatedToPatient)
      .toFixed(2);
  }, [responsibilities, balanceAllocatedToPatient]);

  return {
    patientResponsibility,
    insurancesToUse,
    responsibilities,
    payorResponsibilityTotal,
    balanceAllocatedToPatient,
  };
};

export default useResponsibilities;
