import {
  AppointmentForUI,
  classNames,
  disableStatusesInPast,
} from '@chiroup/core';
import { useDraggable } from '@dnd-kit/core';
import dayjs from 'dayjs';
import React, { useContext, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ScheduleContext } from '../../contexts/schedule.context';
import { useQueryParams } from '../../hooks/useQueryParams';
import AppointmentView from './AppointmentView';

export const minutesToPixels = (minutes: number, zoom: number) => {
  return minutes * zoom;
};

type Props = {
  appointment: AppointmentForUI;
  day: string;
  userId: string;
  disabled?: boolean;
  isRoom?: boolean;
};

const ScheduleEvent: React.FC<Props> = ({
  day,
  userId,
  appointment,
  disabled,
  isRoom = false,
}) => {
  const { disciplines } = useContext(ScheduleContext);
  const { addQueryParams } = useQueryParams();
  const navigate = useNavigate();

  /**
   * Cannot drag:
   * - Recurring appointments
   * - Past appointments that are
   *   - Marked as no-show
   *   - Cancelled
   *   - Checked in
   */
  const inPast = appointment.startTime < dayjs();
  const disabledEvent =
    !!appointment.recurringAppointmentId ||
    (inPast && disableStatusesInPast.includes(appointment.status));

  const treatmentColor = useMemo(() => {
    if (disabled) {
      return 'gray';
    }
    let treatment = null;
    for (const discipline of disciplines) {
      treatment = discipline.treatments.find(
        (t) => t.ID === appointment.treatmentId,
      );
      if (treatment) {
        break;
      }
    }
    return treatment?.color || 'gray';
  }, [disciplines, appointment.treatmentId, disabled]);

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: isRoom ? 'room-' + appointment.id : appointment.id,
    data: {
      day,
      userId,
      appointment,
      color: treatmentColor,
      isRoom,
    },
    disabled: disabledEvent,
  });

  const navigateToAppointment = () => {
    navigate(`/patients/${appointment.patientId}`);
  };

  /**
   * Need to disable they mousedown and put in a onclick
   * listener so we can still open the appoint details
   * even though past appointments can't be modified.
   */
  // const fakeListeners = {
  //   onClick: navigateToAppointment,
  //   onKeyDown: nullEventListener,
  //   onTouchStart: () => nullEventListener,
  // };

  /**
   * Was getting some funky behavior with mouse-down. Some were on
   * the listeners and some were with an 'onClick' on the 'div'. So,
   * all event listeners go on the same data structure.
   */
  if (listeners) listeners.onClick = navigateToAppointment;

  return (
    <div
      style={{
        top: appointment.top,
        visibility: isDragging ? 'hidden' : undefined,
        left: `${appointment.left}%`,
        height: appointment.height || 15,
        width: `${appointment.width || 100}%`,
      }}
      className={classNames(
        'absolute appointment group hover:w-full hover:left-0',
        disabled || disabledEvent ? 'cursor-not-allowed' : '',
      )}
    >
      <Link to={addQueryParams({ open: appointment.id }).link}>
        <div
          ref={setNodeRef}
          {...listeners}
          {...attributes}
          style={{
            visibility: isDragging ? 'hidden' : undefined,
          }}
        >
          <AppointmentView
            patientName={`${appointment.displayValues?.patientName}`}
            start={appointment.startTime}
            isDragging={isDragging}
            width={`${appointment.width || 100}%`}
            duration={appointment.duration}
            color={treatmentColor}
            appointment={appointment}
          />
        </div>
      </Link>
    </div>
  );
};

export default ScheduleEvent;
