import {
  Button,
  ButtonColors,
  LoadingPage,
  OpenClosedStates,
} from '@chiroup/components';
import {
  Appointment,
  AppointmentForUI,
  AppointmentStatuses,
  Appointments,
  AvailableSlotsResponse,
  DisciplineTreatment,
  FeatureFlags,
  orderBy,
} from '@chiroup/core';
import dayjs from 'dayjs';
import qs from 'query-string';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import { ScheduleContext } from '../../contexts/schedule.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import useLocalStorage, { LSType } from '../../hooks/useLocalStorage';
import useLocationAvailability from '../../hooks/useLocationAvailability';
import { useQueryParams } from '../../hooks/useQueryParams';
import patientService, {
  AppointmentDurationChangeParams,
} from '../../services/patient.service';
import { removeQueryParams } from '../common/helpers/link.helpers';
import useDisciplines from '../settings/clinic/useDisciplines';
import ScheduleAppointment from './ScheduleAppointment';
import ScheduleHeader from './ScheduleHeader';
import SchedulePane from './SchedulePane';
import ViewDayModal from './ViewDayModal';
import useAppointments from './hooks/useAppointments';
import useWS from './hooks/useWS';
import useLocationRooms from '../../hooks/useLocationRooms';

export type CalendarView = 'week' | 'day';

export const calendarViews: {
  [key: string]: CalendarView;
} = {
  week: 'week',
  day: 'day',
};

/**
 * This recursively returns all the elements in any array of objects. Used to
 * count the # of available slots over a time period.
 *
 * @param obj
 * @returns
 */
const slots = (obj: any | string[] | null | undefined) => {
  if (!obj) return [] as string[];
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      return obj as string[];
    } else {
      let acc: string[] = [];
      for (const key in obj as string[]) {
        const terms: string[] = slots(obj[key]) as string[];
        acc = acc.concat(terms);
      }
      return acc;
    }
  }
};

const Schedule = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { createToast, removeToastByName } = useContext(ToastContext);
  const { setItem, getItem } = useLocalStorage();
  const { me, selectedLocationFull, hasAccess, sessionId } =
    useContext(MeContext);
  const { availability } = useLocationAvailability({
    clinicId: me?.selectedClinic?.ID,
    locationId: me?.selectedLocation,
  });

  const { data: disciplines } = useDisciplines();
  const location = useLocation();
  const {
    data: appointmentData,
    updateAppointment,
    updateAppointmentTime,
    searchQuery,
    setSearchQuery,
    onMessage,
  } = useAppointments(sessionId);

  useWS(
    `schedule:${me?.selectedClinic?.ID}:${selectedLocationFull?.ID}`,
    onMessage,
  );
  /**
   * This is how the code detects if a user changes a duration or something
   * else on the appointment while dragging. If the duration exists, then
   * that is a switch so the undo and notify know what to do.
   *
   * Apologies for the useRef, could not figure out why useState() was not
   * working with this component architecture.
   */
  const previousAppointmentState = React.useRef<{
    duration: AppointmentDurationChangeParams | null;
    transactionId: string | null;
  }>({ duration: null, transactionId: null });
  const { addQueryParams } = useQueryParams();
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<
    string | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isScheduling, setIsScheduling] = useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );
  const [recurringAppointment, setRecurringAppointment] =
    useState<boolean>(false);
  const [availableSlots, setAvailableSlots] =
    useState<AvailableSlotsResponse | null>(null);
  const [selectedTreatment, setSelectedTreatment] =
    useState<DisciplineTreatment | null>(null);
  const [loadingAvailableSlots, setLoadingAvailableSlots] =
    useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedRooms, setSelectedRooms] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedOptions, setSelectedOptions] = useState<{
    providers: boolean;
    rooms: boolean;
  }>({
    providers: true,
    rooms: false,
  });
  const [viewDaySchedule, setViewDaySchedule] = useState<boolean>(false);
  const { rooms, isFetching } = useLocationRooms({
    clinicId: me?.selectedClinic?.ID,
    locationId: me?.selectedLocation,
  });
  const [dayData, setDayData] = useState<{
    id?: string;
    name?: string;
    day?: string;
    dayName?: string;
    fullDate?: string;
    prop?: string;
  }>({});

  const [recurringData, setRecurringData] = useState<{
    treatmentId?: number;
    displayValues?: {
      patientName: string;
      clinicianName: string;
      treatmentName: string;
    };
    duration?: number;
    dataWithAllConflicts?: {
      id: number;
      conflicts: Appointment[];
      messages: {
        overrideMessage?: string;
        clinicLocationMessage?: string;
        providerMessage?: string;
      };
      timestamp: { date: string; end: number; start: number };
    }[];
  }>({});

  const minMaxTime: {
    minTime: number;
    maxTime: number;
  } = useMemo(() => {
    const availabilityMinMax = Object.values(availability ?? {})?.reduce(
      (obj: { minTime: number; maxTime: number }, timeData) => {
        const { minTimeForDay, maxTimeForDay } = timeData.reduce(
          (
            timeObj: {
              minTimeForDay: number;
              maxTimeForDay: number;
            },
            { start, end },
          ) => {
            const [hour] = start.split(':');
            const startHourNum = Number(hour);
            const [endHour] = end.split(':');
            const endHourNum = Number(endHour);
            if (startHourNum < timeObj.minTimeForDay) {
              timeObj.minTimeForDay = startHourNum;
            }
            if (endHourNum > timeObj.maxTimeForDay) {
              timeObj.maxTimeForDay = endHourNum;
            }
            return timeObj;
          },
          {
            minTimeForDay: 24,
            maxTimeForDay: 0,
          },
        );
        if (minTimeForDay < obj.minTime) {
          obj.minTime = minTimeForDay;
        }
        if (maxTimeForDay > obj.maxTime) {
          obj.maxTime = maxTimeForDay;
        }
        return obj;
      },
      {
        minTime: 24,
        maxTime: 0,
      },
    );

    const apptStarts: {
      earliestApptStart: number;
      latestApptEnd: number;
    } = {
      earliestApptStart: 24,
      latestApptEnd: 0,
    };

    Object.values(appointmentData || {}).forEach((day) => {
      Object.values(day).forEach((provider: any) => {
        provider.appointments.forEach(
          (appt: { startTime: any; duration: number }) => {
            const startTime = Number(
              dayjs(appt.startTime, selectedLocationFull.timezone).format('HH'),
            );

            const endTime = Number(
              dayjs(appt.startTime, selectedLocationFull.timezone)
                .add(appt.duration, 'minutes')
                .format('HH'),
            );

            if (
              !apptStarts.earliestApptStart ||
              startTime < apptStarts.earliestApptStart
            ) {
              apptStarts.earliestApptStart = startTime;
            }
            if (endTime > apptStarts.latestApptEnd) {
              apptStarts.latestApptEnd = endTime;
            }
          },
        );
      });
    });

    const earliestTimeToShow = Math.min(
      availabilityMinMax.minTime,
      apptStarts.earliestApptStart,
    );
    const latestTimeToShow = Math.max(
      availabilityMinMax.maxTime,
      apptStarts.latestApptEnd,
    );

    return {
      minTime: earliestTimeToShow,
      maxTime: latestTimeToShow,
    };
  }, [appointmentData, availability, selectedLocationFull.timezone]);

  useEffect(() => {
    const today = dayjs();
    const queryParams = qs.parse(location.search);
    const { startDate, endDate } = queryParams;
    const startDateToSet = startDate
      ? startDate
      : dayjs(today).startOf('week').format('YYYY-MM-DD');
    const endDateToSet = endDate
      ? endDate
      : dayjs(today).endOf('week').format('YYYY-MM-DD');
    setIsLoading(true);
    setSearchQuery({
      startDate: startDateToSet as string,
      endDate: endDateToSet as string,
    });
    setIsLoading(false);
  }, [location.search, setSearchQuery]);

  useEffect(() => {
    const users = getItem(LSType.both, 'scheduleSelectedUsers') || {};
    const getProvidersOrRooms = getItem(LSType.both, 'providersOrRooms');
    const rooms = getItem(LSType.both, 'scheduleSelectedRooms') || {};
    if (users) {
      setSelectedUsers(users.selectedScheduleUsers);
    }
    if (rooms) {
      setSelectedRooms(rooms.selectedScheduleRooms);
    }
    if (!getProvidersOrRooms) {
      setItem(LSType.both, 'providersOrRooms', {
        providers: true,
        rooms: false,
      });
      setSelectedOptions({
        providers: true,
        rooms: false,
      });
    } else {
      setSelectedOptions(getProvidersOrRooms);
    }
  }, [getItem, setItem]);

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    const open = (queryParams.open || '') as string;
    const mode = (queryParams.mode || '') as string;
    if (open) {
      if (mode === 'recurring') {
        setRecurringAppointment(true);
      } else if (open !== 'add') {
        setSelectedAppointmentId(open);
        setRecurringAppointment(false);
      }
      setIsScheduling(OpenClosedStates.Open);
    } else {
      setIsScheduling(OpenClosedStates.Closed);
    }
    setIsLoading(false);
  }, [location, setIsScheduling, setIsLoading]);

  const selectTreatment = useCallback(
    async (treatment: DisciplineTreatment | null) => {
      setSelectedTreatment(treatment || null);
      if (!me?.selectedClinic?.ID || !me?.selectedLocation) return;
      if (!treatment) {
        setAvailableSlots(null);
        return;
      }
      setLoadingAvailableSlots(true);
      let res = null;

      try {
        res = await patientService.getAvailability(me?.selectedClinic?.ID, {
          location: me?.selectedLocation,
          treatment: treatment.ID,
          startDate: searchQuery.startDate,
          endDate: searchQuery.endDate,
        });
      } catch (e: any) {
        console.error({ 'Error Retrieving Availability': e });
        createToast({
          title: 'Error',
          description:
            e?.response?.data?.message || e?.message || 'Something went wrong.',
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }
      setAvailableSlots(res);
      setLoadingAvailableSlots(false);

      /**
       * If the user selects a treatment, this looks at the number of slots that
       * are returned and shows a toast if there are not any. Previous behavior
       * was to just stop spinning which was not helpful enough to my eyes.
       */
      if (res) {
        (slots(res || {}) || []).length === 0 &&
          createToast({
            title: 'No Availability',
            description: `No availability for ${treatment.name} between ${dayjs(
              searchQuery.startDate,
            ).format('MMM D, YYYY')} and ${dayjs(searchQuery.endDate).format(
              'MMM D, YYYY',
            )}.`,
            type: ToastTypes.Info,
            duration: 5000,
          });
      }
    },
    [me?.selectedClinic?.ID, me?.selectedLocation, searchQuery, createToast],
  );

  const scheduleApptFromDoubleClick = (
    clinician: string,
    startTime: number,
    date: string,
  ) => {
    if (startTime < dayjs().valueOf()) return;
    setIsScheduling(OpenClosedStates.Open);
    const params: {
      [key: string]: string | number;
    } = {
      open: 'add',
      clinician,
      startTime,
      date,
    };

    addQueryParams(params).navigate();
  };

  const createSavingToast = (title?: string) => {
    setIsSaving(true);
    removeToastByName('globalSavingToast');

    /**
     * When a save fires off, close any existing TIMED toasts. With a
     * 10-second delay. Someone can certainly move a toast and _then_
     * set its time within 10 seconds.
     */
    removeToastByName('globalSuccessToast');
    removeToastByName('globalFailToast');
    createToast({
      title: title || 'Saving...',
      description: null,
      type: ToastTypes.Info,
      name: 'globalSavingToast',
    });
  };

  const undoLatestChange = async (e: any) => {
    e.preventDefault();
    createSavingToast('Undoing...');
    removeToastByName('globalSuccessToast');
    try {
      const $ptr = previousAppointmentState.current;
      /**
       * The order of the 'if' tests matter. Transaction ids are always set as
       * they are needed for a notification. So, the way to detect if the _last_
       * thing that was done is a simple duration change, that is the flag to
       * choose the REST call to wait for.
       */
      if ($ptr.duration) {
        await updateAppointmentTime({
          ...$ptr.duration,
        });
      } else if ($ptr.transactionId) {
        await patientService.lastAppointmentUndo(
          $ptr.transactionId,
          me?.selectedClinic?.ID,
        );
      }
    } catch (e: any) {
      createToast({
        title: 'Error',
        description: (e?.message || 'Something went wrong') + '.',
        type: ToastTypes.Fail,
        name: 'globalFailToast',
        duration: 3000,
      });
    }

    removeToastByName('globalSavingToast');
    setIsSaving(false);
  };

  const notifyPatient = async (e: any) => {
    e.preventDefault();
    createSavingToast('Sending...');
    removeToastByName('globalSuccessToast');

    await patientService.lastAppointmentNotify(
      previousAppointmentState.current.transactionId || '',
    );
    removeToastByName('globalSavingToast');
    setIsSaving(false);
  };

  /**
   * This handles the response back from updating a duration
   * _or_ the day and time.
   */
  const toastTheResponse = ({
    success,
    error,
    response,
  }: {
    success: boolean;
    error?: any;
    response?: any;
  }): any => {
    removeToastByName('globalSavingToast');

    if (success === false) {
      createToast({
        title: 'Error',
        description: error?.message || 'Something went wrong.',
        type: ToastTypes.Fail,
        name: 'globalFailToast',
        duration: 5000,
      });
    } else {
      /**
       * The next line is the secret-sauce that supports undo and notify.
       */
      previousAppointmentState.current.transactionId = response?.transactionId;
      createToast({
        title: 'Success',
        description: (
          <div>
            <h1>Appointment updated successfully.</h1>
            <div className="text-right mt-6 -mr-8">
              <Button
                text="Undo"
                tooltip="Undo the last appointment change."
                onClick={undoLatestChange}
                loading={false}
                color={ButtonColors.plainWithBorder}
                className="inline-block mr-4 text-xs hover:bg-primary-500 hover:text-white"
              />
              <Button
                text="Notify"
                tooltip="Notify the patient of the change."
                onClick={notifyPatient}
                loading={false}
                color={ButtonColors.plainWithBorder}
                className="inline-block text-xs hover:bg-primary-500 hover:text-white"
              />
            </div>
          </div>
        ),
        type: ToastTypes.Success,
        name: 'globalSuccessToast',
        duration: 10000,
      });
    }

    return {
      success,
      error,
      response,
    };
  };

  const users = useMemo(() => {
    if (!selectedOptions.providers) return [];
    const usersSent = Object.values(
      Object.values(appointmentData || {})?.[0] || {},
    )
      ?.filter((user: any) => !user.isRoom)
      ?.map((user: any) => {
        return {
          id: user.id,
          name: user.name,
          profileImage: user.profileImage,
          ID: user.ID,
          fname: user.fname,
          lname: user.lname,
        };
      })
      ?.sort(orderBy('name'));
    const noUsersSelected =
      !selectedUsers ||
      Object.values(selectedUsers || {}).every((value) => !value);
    if (noUsersSelected) {
      return usersSent || [];
    }
    return usersSent?.filter((user) => selectedUsers[user.id]) || [];
  }, [appointmentData, selectedOptions.providers, selectedUsers]);
  const nonArchivedRooms = useMemo(() => {
    return rooms?.filter((room) => !room.archived);
  }, [rooms]);

  const roomOptions = useMemo(() => {
    if (!selectedOptions.rooms) return [];
    const noRoomsSelected =
      !selectedRooms ||
      Object.values(selectedRooms || {}).every((value) => !value);

    if (noRoomsSelected) {
      return nonArchivedRooms || [];
    } else {
      return nonArchivedRooms?.filter((room) => selectedRooms[room.id]) || [];
    }
  }, [nonArchivedRooms, selectedOptions.rooms, selectedRooms]);

  if (!hasAccess(FeatureFlags.scheduling)) {
    return null;
  }

  const rootUpdateAppointment = async ({
    day,
    time,
    userId,
    previousDay,
    previousUserId,
    wholeAppointment,
    timezone,
  }: {
    day: string;
    time: number;
    userId: string;
    previousDay: string;
    previousUserId: string;
    wholeAppointment: AppointmentForUI;
    timezone: string;
  }): Promise<void> => {
    if (isSaving) return;

    previousAppointmentState.current.duration = null;
    previousAppointmentState.current.transactionId = null;
    createSavingToast();
    const res = await updateAppointment({
      day,
      time,
      userId,
      previousDay,
      previousUserId,
      wholeAppointment,
      timezone,
    });
    setIsSaving(false);
    if (!res) return;
    return toastTheResponse(res);
  };

  const viewOneDay = (
    { id, name }: { id: string; name: string },
    {
      day,
      dayName,
      fullDate,
      prop,
    }: { day: string; dayName: string; fullDate: string; prop: string },
  ) => {
    setDayData({ id, name, day, dayName, fullDate, prop });
    setViewDaySchedule(true);
  };

  const goBack = () => {
    setIsLoading(true);
    const { startDate: previousStartDate, endDate: previousEndDate } =
      getItem(LSType.both, 'previousScheduleDateRange') || {};

    setSearchQuery({
      startDate: previousStartDate,
      endDate: previousEndDate,
    });

    setIsLoading(false);
    return addQueryParams({
      startDate: previousStartDate,
      endDate: previousEndDate,
    }).navigate();
  };

  //appointment data will need to tell us what room the appointment is in

  return (
    <ScheduleContext.Provider
      value={{
        disciplines: disciplines || [],
      }}
    >
      <div className="flex flex-row h-full w-full">
        <div className="flex h-full w-full flex-col print:hidden">
          {!appointmentData || isLoading ? (
            <LoadingPage />
          ) : (
            <>
              <ScheduleHeader
                start={searchQuery.startDate}
                end={searchQuery.endDate}
                selectTreatment={selectTreatment}
                selectedTreatment={selectedTreatment}
                loadingAvailableSlots={loadingAvailableSlots}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                setSelectedRooms={setSelectedRooms}
                selectedRooms={selectedRooms}
                goBack={goBack}
                users={users}
                setSelectedOptions={setSelectedOptions}
                selectedOptions={selectedOptions}
                nonArchivedRooms={nonArchivedRooms}
                isFetching={isFetching}
              />
              <SchedulePane
                appointments={appointmentData as Appointments}
                updateAppointment={rootUpdateAppointment}
                availableSlots={availableSlots}
                selectedTreatment={selectedTreatment}
                selectedUsers={selectedUsers}
                minMaxTime={minMaxTime}
                viewOneDay={viewOneDay}
                scheduleApptFromDoubleClick={scheduleApptFromDoubleClick}
                searchQuery={searchQuery}
                users={users}
                roomOptions={roomOptions}
              />
            </>
          )}
        </div>
        {isScheduling === OpenClosedStates.Open && (
          <ScheduleAppointment
            state={isScheduling}
            onClose={(afterClose?: () => void) => {
              if (afterClose) {
                afterClose();
              } else {
                const newParams = removeQueryParams(search, [
                  'clinician',
                  'open',
                  'startTime',
                  'patient',
                  'duration',
                  'patientName',
                  'treatment',
                  'mode',
                ]);
                navigate(`${pathname}${newParams}`);
              }
              setSelectedAppointmentId(null);
              selectTreatment(null);

              if (recurringAppointment) {
                setRecurringData({});
                setRecurringAppointment(false);
              }
            }}
            sessionId={sessionId}
            selectedAppointmentId={selectedAppointmentId}
            timezone={selectedLocationFull?.timezone || ''}
            recurringAppointment={recurringAppointment}
            setRecurringData={setRecurringData}
            recurringData={recurringData}
            disciplines={disciplines}
          />
        )}
        <ViewDayModal
          isOpen={viewDaySchedule}
          appointments={appointmentsWithoutCanceled({
            appointmentData,
            dayData,
          })}
          dayData={dayData}
          close={() => {
            setDayData({});
            setViewDaySchedule(false);
          }}
        />
      </div>
    </ScheduleContext.Provider>
  );
};

export default Schedule;

const appointmentsWithoutCanceled = ({ appointmentData, dayData }: any) => {
  return dayData?.fullDate && dayData?.id
    ? appointmentData?.[dayData?.fullDate]?.[dayData?.id]?.appointments?.filter(
        (appointment: any) => {
          return appointment.status !== AppointmentStatuses.Canceled;
        },
      )
    : [];
};
